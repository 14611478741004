.LoginModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60vw;
  max-width: 500px;
  background: rgba(194, 224, 249, 0.85);
  background-size: cover;
  border-radius: 5px;
  padding: 20px;
  padding: 20px;
}

#logo {
  height: 30vh;
}

#background {
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
